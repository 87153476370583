@import 'src/assets/styles/mixins.scss';

.box {
  position: absolute;
  left: 100px;
  top: 120px;
  max-width: 300px;

  @include media-xxl {
    max-width: 500px;
    top: 200px;
  }

  h1 {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    font-size: 20px;
    color: var(--white-color);
    @include roboto-regular();

    @include media-xxl {
      font-size: var(--font-size-xl);
      margin-bottom: 60px;
    }

    span {
      color: var(--accent-color);
      margin-right: 3px;
      font-weight: 700;
    }

    svg {
      width: 57px;
      height: 41px;
      fill: var(--accent-color);
      margin-right: 6px;

      @include media-xxl {
        width: 85px;
        height: 62px;
        margin-right: 10px;
      }
    }
  }

  .title {
    font-size: 25px;
    margin-bottom: 15px;
    color: var(--white-color);
    @include roboto-medium();

    @include media-xxl {
      font-size: 40px;
      margin-bottom: 30px;
    }
  }

  .description {
    font-size: 20px;
    color: var(--gray-color);
    margin-bottom: 30px;
    line-height: 25px;
    @include roboto-light();

    @include media-xxl {
      font-size: 30px;
      line-height: 35px;
      margin-bottom: 60px;
    }
  }

  .logOut {
    max-width: 200px;
    @include media-xxl {
      max-width: 310px;
    }
  }
}
