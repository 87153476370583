@import 'src/assets/styles/mixins.scss';

.container {
  display: flex;
  flex-direction: column;

  height: 100%;
  padding: 30px 40px;

  @include media-xxl {
    padding: 30px 60px;
  }


  .header {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    @include media-xxl {
      margin-bottom: 25px;
    }

    .title {
      font-size: var(--font-size-m);
      color: var(--white-color);
      @include roboto-medium();

      @include media-xxl {
        font-size: var(--font-size-heading1);
      }
    }
  }


}
