@import 'src/assets/styles/mixins.scss';

.container {
  width: 100%;

  .title {
    margin-bottom: 7px;
    color: var(--accent-color);
    font-size: 20px;
    @include roboto-medium();

    @include media-xxl {
      font-size: var(--font-size-heading1);
      margin-bottom: 12px;
    }
  }

  .description {
    color: var(--gray-dark-color);
    font-size: var(--font-size-s);
    margin-bottom: 30px;
    @include roboto-regular();

    @include media-xxl {
      margin-bottom: 55px;
      font-size: 18px;
    }
  }

  .input {
    margin: 20px 0;
  }

  a {
    display: block;
    color: var(--accent-color);
    font-size: var(--font-size-m);
    margin-top: 15px;
  }

  .button {
    margin-top: 10px;
  }

}