@import 'src/assets/styles/mixins.scss';

.container {
  width: 100%;
  padding: 20px;
  width: 621px;

  @include media-xxl {
    padding: 25px;
    width: 949px;
  }

  .header {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    svg {
      cursor: pointer;
    }

    .close {
      width: 9px;
      height: 9px;

      @include media-xxl {
        width: auto;
        height: auto;
      }
    }
  }
}

.body {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .icon {

    svg {
      width: 20px;
      height: 20px;

      @include media-xxl {
        width: 30px;
        height: 30px;
      }
    }
  }

  .title {
    margin-bottom: 5px;
    font-size: var(--font-size-heading);
    @include roboto-medium();

    @include media-xxl {
      margin-bottom: 10px;
    }
  }

  .description {
    margin-bottom: 25px;
    max-width: 360px;
    text-align: center;

    @include roboto-light();

    @include media-xxl {
      max-width: 560px;
    }
  }

  .actions {
    display: flex;
    padding: 0 10px;
    margin-bottom: 20px;
    gap: 10px;
    width: 100%;

    @include media-xxl {
      padding: 0 20px;
    }

    .input {
      flex: 3;
    }

    .select {
      flex: 2;
      min-width: auto;
    }

    button {
      //margin-left: 10px;
      flex: 1;
    }
  }
}