@import 'src/assets/styles/mixins.scss';

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;

  .icon {

    svg {
      width: 20px;
      height: 20px;

      @include media-xxl {
        width: 30px;
        height: 30px;
        margin-bottom: 15px;
      }
    }
  }

  .title {
    margin-bottom: 20px;
    font-size: var(--font-size-heading);
    @include roboto-medium();
  }

  .selectorControls {
    display: flex;
    margin-bottom: 30px;
    gap: 10px;

    .selectorBtn {
      font-size: var(--font-size-xs);
      padding: 4px 8px;
      border-radius: 5px;
      border: 1px solid #474B56;
      background-color: transparent;
      cursor: pointer;
      @include roboto-regular();

      &.selected {
        background-color: var(--accent-color);
        @include roboto-medium();
      }

      @include media-xxl {
        padding: 10px 13px;
        border-radius: 10px;
        font-size: 16px;
      }
    }
  }

  .permissions {
    display: flex;
    width: 100%;
    justify-content: space-between;
    //padding: 0 80px;
    margin-bottom: 30px;
    //max-height: 250px;

    .column {
      display: flex;
      flex-direction: column;

      .columnName {
        color: var(--white-color);
        margin-bottom: 10px;
        @include roboto-medium();
        font-size: var(--font-size-s);

        @include media-xxl {
          margin-bottom: 15px;
          font-size: 18px;
        }
      }
    }

    .permissionItem {
      @include checkbox-label();

      input + label {
        font-size: var(--font-size-s);


        @include media-xxl {
          font-size: 18px;
          margin-bottom: 10px;
        }
      }

      input + label::before {
        border-color: var(--accent-color);


      }

    }
  }

  .errorText {
    color: var(--error-color);
    margin-bottom: 10px;
  }


  .submitBtn {
    display: flex;
    justify-content: center;
    width: 100%;

    button {
      max-width: 140px;
      height: 42px;

      @include media-xxl {
        max-width: 220px;
        height: 65px;
      }
    }
  }
}