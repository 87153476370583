@import 'src/assets/styles/mixins.scss';

section {
  display: flex;
  flex-direction: column;
  align-items: center;
  .icon {
    transform: scale(0.7);
    margin-bottom: -35px;

    @include media-xxl {
      transform: scale(1);
      margin-bottom: 35px;
    }
  }

  h1 {
    color: var(--white-color);
    font-size: 40px;
    margin-bottom: 20px;
    line-height: 40px;
    @include roboto-medium();

    @include media-xxl {
      font-size: 65px;
      line-height: 75px;
      margin-bottom: 40px;
    }
  }

  .restart {
    max-width: 210px;
    margin-bottom: 15px;

    @include media-xxl {
        margin-bottom: 40px;
    }
  }

  p {
    color: var(--gray-color);
    font-size: 20px;
    @include roboto-light();

    @include media-xxl {
      margin-bottom: 0;
      font-size: 30px;
      line-height: 35px;
    }
  }
}

.brandLogo {
  position: absolute;
  bottom: 0;
  width: 585px;
  transform: translateY(90px);

  @include media-xxl {
    width: 922px;
    transform: translateY(0px);
  }
}
