@import 'src/assets/styles/mixins.scss';

.dialog {
  background-color: rgba(0, 0, 0, 0.5);

  :global(.MuiPaper-root) {
    width: 90vw;
    max-width: 432px;
    border-radius: 10px;
    background: var(--secondary-color);
    color: var(--white-color);
    padding: 20px;

    @include media-xxl {
      max-width: 678px;
      padding: 40px;
      border-radius: 20px;
    }
  }

  .title {
    padding: 0;
    margin-top: 12px;
    margin-bottom: 4px;
    font-size: var(--font-size-heading);
    @include roboto-medium();

    @include media-xxl {
      margin-top: 5px;
      margin-bottom: 10px;
    }
  }

  .content {
    padding: 0;
    margin: 0;
  }

  .text {
    @include roboto-light();

    font-size: var(--font-size-s);
    color: var(--gray-color);
    margin-bottom: 5px;

    @include media-xxl {
      font-size: var(--font-size-m);
    }
  }

  .actions {
    padding: 24px 0 0;

    button {
      width: 140px;
      padding: 8px;

      @include media-xxl {
        padding: 20px;
        width: 220px;
      }
    }
  }
}