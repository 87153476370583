@import 'src/assets/styles/mixins.scss';

.container {
  width: 100%;
  margin-bottom: 20px;
  padding: 10px 15px;
  background-color: var(--secondary-color);
  border-radius: 10px;
  color: var(--white-color);

  @include media-xxl {
    padding: 25px 45px;
    margin-bottom: 30px;
  }

  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;
    border-bottom: 1px solid #474B56;

    &:last-child {
      border: none;
    }

    @include media-xxl {
      padding: 12px 10px;
    }

    .info {
      margin-right: 6px;

      .earningValues {
        display: flex;
        font-size: var(--font-size-heading);
        margin-right: 10px;
        margin-bottom: 5px;
        white-space: nowrap;
        @include roboto-medium();

        @include media-xxl {
          margin-bottom: 8px;
        }

        /*& span {
            margin-left: 10px;
            color: #47AF2D;
            font-size: var(--font-size-s);
            white-space: nowrap;

            @include media-xxl {
                font-size: 17px;
            }

            &.loss {
                color: #F2383A
            }
        }*/
      }

      .typeTitle {
        font-size: var(--font-size-s);
        color: var(--gray-dark-color);
        text-transform: capitalize;
        @include roboto-medium();

        @include media-xxl {
          font-size: 17px;
        }
      }
    }

    .chart {
      width: 120px;
      height: 60px;
    }
  }
}