@import 'src/assets/styles/mixins.scss';

.container {
  width: 100%;

  :global {
    .MuiTable-root {

      th,
      td {
        color: var(--gray-dark-color);
        border-bottom: 1px solid #474B56;
        font-size: inherit;
        @include roboto-regular();
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

      }

      thead {
        background-color: transparent;

        th {
          color: var(--white-color) //@include inter-semibold();
        }
      }

      tbody {
        tr:hover {
          background-color: transparent;
          cursor: auto;
        }
      }
    }
  }
}

.emptyElement {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
}
