@import 'src/assets/styles/mixins.scss';

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 20px;
  padding-right: 20px;

  .title {
    margin-bottom: 35px;
    margin-top: 15px;
    font-size: var(--font-size-heading);
    @include roboto-medium();

    @include media-xxl {
      margin-bottom: 50px;
    }
  }
;

  .subtitle {
    margin-bottom: 13px;
    font-size: var(--font-size-sm);
    @include roboto-medium();

    @include media-xxl {
      font-size: 22px;
    }
  }

  .list {
    width: 100%;
    margin-bottom: 20px;
    list-style-type: none;
    font-size: inherit;
    @include roboto-light();

    li {
      color: var(--gray-dark-color);

      &::before {
        content: "✓";
        padding-right: 10px;
        color: var(--accent-color);
      }

      @include media-xxl {
        margin-bottom: 10px;
      }
    }
  }

  .actions {
    display: flex;
    margin-bottom: 20px;

    @include media-xxl {
      margin-bottom: 30px;
    }

    .select {
      margin-right: 10px;
      min-width: 248px;

      @include media-xxl {
        min-width: 360px;
        margin-right: 15px;
      }
    }

    button {
      max-height: 42px;
      width: 141px;

      @include media-xxl {
        max-height: 65px;
        width: 220px;
      }
    }
  }
}