@import 'src/assets/styles/mixins.scss';

.container {
  width: 100%;
  margin-bottom: var(--content-padding);

  padding: 15px;
  background-color: var(--secondary-color);
  border-radius: 10px;

  @include media-xxl {
    padding: 25px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    h2 {
      color: var(--white-color);
      margin-bottom: 20px;
      margin-right: 10px;
      font-size: var(--font-size-heading);
      @include roboto-medium();

      span {
        color: var(--accent-color);
        font-weight: 500;
      }
    }
  }

  .table {
    width: 100%;
    @include roboto-regular();

    p {
      color: var(--white-color);
    }

    .username {
      color: var(--gray-dark-color);
    }

    .totalSpent {
      color: var(--accent-color);
      @include roboto-medium();
    }
  }
}