@import 'src/assets/styles/mixins.scss';

.container {
  width: 90vw;
  max-width: 432px;
  padding: 20px;

  @include media-xxl {
    max-width: 678px;
    padding: 25px;
  }

  .body {
    .title {
      margin-top: 12px;
      margin-bottom: 4px;
      font-size: var(--font-size-heading);
      @include roboto-medium();

      @include media-xxl {
        margin-top: 20px;
        margin-bottom: 10px;
      }
    }

    .text {
      @include roboto-light();
      font-size: var(--font-size-s);
      color: var(--gray-color);
      margin-bottom: 5px;

      @include media-xxl {
        font-size: var(--font-size-m);
      }
    }

    .actions {
      display: flex;
      justify-content: flex-end;
      width: 100%;

      button {
        width: 140px;

        @include media-xxl {
          width: 220px;
        }
      }
    }
  }
}