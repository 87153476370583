@import 'src/assets/styles/mixins.scss';

.box {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  padding: 20px 28px;

  .tag {
    font-size: 18px;
    width: fit-content;
    padding: 8px 18px;
    border-radius: 10px;
    text-transform: capitalize;
  }

  .member {
    font-size: 19px;
    color: var(--white-color);
  }
}

.line {
  background: var(--dark-border-color);
  height: 1px;
  width: 100%;
}
