@import 'src/assets/styles/mixins.scss';

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .icon {
    width: 75px;
    height: 75px;
    margin-bottom: 20px;

    @include media-xxl {
      width: 140px;
      height: 140px;
      margin-bottom: 36px;
    }
  }

  .title {
    margin-bottom: 10px;
    color: var(--accent-color);
    font-size: 20px;
    @include roboto-medium();

    @include media-xxl {
      font-size: var(--font-size-heading1);
      margin-bottom: 14px;
    }
  }

  .description {
    text-align: center;
    font-size: var(--font-size-s);
    margin-bottom: 20px;
    @include roboto-regular();

    @include media-xxl {
      font-size: 18px;
      margin-bottom: 39px;
    }
  }

}