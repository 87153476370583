@import 'src/assets/styles/mixins.scss';

.header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: var(--primary-color);
  margin: 0 40px;
  padding: 10px 0;
  color: var(--white-color);
  border-bottom: 1px solid #515151;

  @include media-xxl {
    margin: 0 60px;
    padding: 15px 0;
  }


  .currentSession {
    margin-left: 15px;
  }

  span {
    color: var(--accent-color);
  }

  .settingsBox {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: var(--white-color);
  }

  .settings {
    position: relative;
    top: 2px;
    cursor: pointer;

    svg {
      width: 17px;
      height: 16px;
      fill: var(--white-color);

      @include media-xxl {
        width: 25px;
        height: 23px;
      }
    }
  }

  .avatar {
    width: 62px;
    height: 62px;
    background-color: var(--gray-color);
    border-radius: 50%;
    margin-left: 30px;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}
