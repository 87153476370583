@import 'src/assets/styles/mixins.scss';

.container {
  width: var(--modal-width);
  padding: 20px;

  @include media-xxl {
    padding: 25px;
  }

  .header {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    svg {
      cursor: pointer;
    }

    .close {
      width: 9px;
      height: 9px;

      @include media-xxl {
        width: auto;
        height: auto;
      }
    }
  }
}

.body {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .icon {

    @include media-xxl {
      margin-bottom: 10px;

    }

    svg {
      width: 20px;
      height: 20px;

      @include media-xxl {
        width: 30px;
        height: 30px;
      }
    }
  }

  .title {
    margin-bottom: 10px;
    font-size: var(--font-size-heading);
    font-weight: 500;
  }

  .description {
    margin-bottom: 15px;
    max-width: 300px;
    text-align: center;
    @include roboto-light();

    @include media-xxl {
      margin-bottom: 25px;
      max-width: 500px;
    }
  }

  .actions {
    width: 100%;
    padding: 0 20px;

    @include media-xxl {
      padding: 0 40px;
    }

    .creatorsList {
      width: 100%;
    }
  }

}