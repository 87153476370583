@import 'src/assets/styles/mixins.scss';

.assignCreator {
  display: flex;
  margin-bottom: 10px;
  width: 100%;

  @include media-xxl {
    margin-bottom: 20px;
  }

  .input {
    flex: 3;
  }

  button {
    margin-left: 10px;
    flex: 1;
  }
}