@import 'src/assets/styles/mixins.scss';

.container {
  width: 100%;
  display: flex;
  margin-bottom: 20px;
  gap: 20px;
  color: var(--white-color);

  @include media-xxl {
    gap: 30px;
  }

  .tile {
    padding: 16px;
    flex: 1;
    background-color: var(--secondary-color);
    border-radius: 10px;

    @include media-xxl {
      padding: 24px;
    }

    .title {
      margin-bottom: 6px;

      @include media-xxl {
        margin-bottom: 10px;
      }
    }

    .value {
      font-size: 18px;

      @include media-xxl {
        font-size: var(--font-size-heading1);
      }
    }

  }
}