@import 'src/assets/styles/mixins.scss';

.container {
  width: 100%;
  padding: 20px;
  width: var(--modal-width);

  @include media-xxl {
    padding: 25px;

  }

  .header {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    .close {
      width: 9px;
      height: 9px;

      @include media-xxl {
        width: auto;
        height: auto;
      }
    }

    svg {
      cursor: pointer;
    }
  }
}

.body {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .icon {
    @include media-xxl {
      margin-bottom: 20px;
    }

    svg {
      width: 20px;
      height: 20px;

      @include media-xxl {
        width: 30px;
        height: 30px;
      }
    }
  }

  .title {
    margin-bottom: 5px;
    font-size: var(--font-size-heading);
    @include roboto-medium();

    @include media-xxl {
      margin-bottom: 10px;
    }
  }

  .description {
    margin-bottom: 15px;
    max-width: 360px;
    text-align: center;

    @include roboto-light();

    @include media-xxl {
      margin-bottom: 25px;
      max-width: 560px;
    }
  }

  .actions {
    display: flex;
    padding: 0 20px;
    margin-bottom: 10px;
    width: 100%;

    @include media-xxl {
      padding: 0 30px;
      margin-bottom: 20px;
    }

    .select {
      flex: 3;
    }

    .updateBtn {
      margin-left: 10px;
      flex: 1;
    }
  }
}