@import 'src/assets/styles/mixins.scss';

.chart {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  padding: 15px;
  background-color: var(--secondary-color);
  border-radius: 10px;

  @include media-xxl {
    margin-bottom: 30px;
    padding: 25px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    h2 {
      font-size: var(--font-size-heading);
      color: var(--white-color);
      margin-right: 10px;
      margin-bottom: 10px;
      @include roboto-medium();

      @include media-xxl {
        font-size: var(--font-size-heading0);
        margin-bottom: 15px;
      }
    }

    .statuses {
      display: flex;
      align-items: center;
      gap: 10px;
      color: var(--white-color);
      font-size: var(--font-size-xs);
      margin-bottom: 10px;
      white-space: nowrap;

      div {
        padding: 6px 8px;
        background-color: transparent;
        border: 1px solid var(--dark-border-color);
        border-radius: 5px;
        cursor: pointer;
        @include roboto-regular();

        &.active {
          background-color: var(--accent-color);
          @include roboto-medium();
        }
      }

      @include media-xxl {
        font-size: var(--font-size-sm);
        margin-bottom: 15px;
        div {
          padding: 12px;
          border-radius: 10px;
        }
      }
    }
  }

  .waterMark {
    position: absolute;
    display: flex;
    align-items: center;
    right: 34px;
    bottom: 10px;
    font-size: var(--font-size-m);
    @include roboto-medium();
    color: white;

    span {
      opacity: 20%;
    }

    img {
      position: relative;
      width: 42px;
      height: 32px;
      margin-right: 10px;
      opacity: 80%;

      @include media-xxl {
        width: 65px;
        height: 47px;
      }
    }

    @include media-xxl {
      font-size: 30px;
      bottom: 4px;

    }
  }

  &.halfView {
    .header {
      .statuses {
        flex-wrap: wrap;
      }
    }
  }
}