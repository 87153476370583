@import 'src/assets/styles/mixins.scss';

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 30px 40px;

  @include media-xxl {
    padding: 30px 60px;
  }

  .connectBtn {
    height: 34px;
    width: auto;
    border-radius: 5px;
    @include roboto-regular();

    @include media-xxl {
      height: 50px;
      border-radius: 10px;
    }
  }

  .header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .title {
      font-size: var(--font-size-m);
      color: var(--white-color);
      margin-right: 27px;

      @include roboto-medium();

      @include media-xxl {
        font-size: var(--font-size-heading1);
      }
    }
  }

  .content {
    height: 100%;
    background-color: var(--secondary-color);
    border-radius: 10px;

    .table {
      align-self: flex-start;

      p {
        font-size: inherit;
        color: var(--white-color);
        @include roboto-regular();

        span {
          color: var(--accent-color);
        }

        &.training {
          text-align: center;
          span {
            display: block;
          }
        }
      }

      p.secondary {
        font-size: var(--font-size-xs);
        color: var(--gray-dark-color);

        @include media-xxl {
          font-size: 16px;
        }
      }

      .creator {
        display: flex;
        align-items: center;

        .avatar {
          margin-right: 20px;
          min-width: 40px;
          height: 40px;
          border-radius: 50%;
          background-color: var(--gray-color);

          img {
            object-fit: cover;
            min-width: 40px;
            height: 40px;
            border-radius: 50%;
          }

          @include media-xxl {
            min-width: 60px;
            height: 60px;

            img {
              min-width: 60px;
              height: 60px;
            }
          }
        }

        .info {
          display: flex;
          flex-direction: column;

          .manageBtn {
            padding: 4px 10px;
            font-size: var(--font-size-xs);
            width: 50px;
            margin-top: 6px;
            border-radius: 5px;

            @include media-xxl {
              width: 100px;
              padding: 6px 20px;
              font-size: var(--font-size-sm);
            }
          }
        }
      }

      .actions {
        display: flex;
        align-items: center;

        .ofButton {
          max-width: 94px;
          max-height: 34px;

          @include media-xxl {
            max-width: 133px;
            max-height: 50px;
          }
        }

        .info {
          width: 13px;
          height: 13px;
          margin-left: 10px;
          cursor: pointer;
        }
      }

      .sessionStatus {
        padding: 6px 18px;
        border-radius: 10px;
        text-transform: capitalize;

        &.active {
          background-color: #e1f8e6;
          color: #136730;
        }

        &.inactive, &.unpaid {
          background-color: #f8e1e1;
          color: #c60202;
        }

        &.notVerified {
          background-color: var(--gray-light-color);
          color: var(--gray-dark-color);
        }

        &.dataSync {
          background-color: #F5F3EF;
          color: #F3BA2F;
        }
      }
    }
  }
}
