@import 'src/assets/styles/mixins.scss';

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .icon {

    svg {
      width: 20px;
      height: 20px;

      @include media-xxl {
        width: 30px;
        height: 30px;
        margin-bottom: 15px;
      }
    }

  }

  .title {
    margin-bottom: 30px;
    font-size: var(--font-size-heading);
    @include roboto-medium();

    @include media-xxl {
      margin-bottom: 50px;
    }
  }

  .actions {
    width: 100%;
    padding: 0 10px;
    margin-bottom: 20px;

    @include media-xxl {
      padding: 0 20px;
    }

    .input {
      margin-bottom: 17px;

      @include media-xxl {
        margin-bottom: 25px;
      }
    }
  }

  .colorChanger {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .activePalleteColor {
      width: 25px;
      height: 25px;
      margin-left: 10px;
      border-radius: 50%;
      cursor: pointer;

      @include media-xxl {
        width: 40px;
        height: 40px;
        margin-left: 15px;
      }
    }
  }

  .nextBtn {
    display: flex;
    justify-content: center;
    width: 100%;


  }
}

.pallete {
  padding: 10px;
  overflow: hidden;
  margin: 0 !important;
  //span {
  //    div {
  //        width: 20px !important;
  //        height: 20px !important;

  //        @include media-xxl {
  //            width: auto !important;
  //            height: auto !important;
  //        }
  //    }
  //}
}