.tooltip {
  :global {
    .MuiTooltip-tooltip {
      max-width: 210px;
      padding: 6px;
    }

    .MuiTooltip-popper {
      margin-bottom: 15px;
    }
  }
}

.tooltipCell {
  cursor: pointer;
}