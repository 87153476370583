@import 'src/assets/styles/mixins.scss';

.filters {
  display: flex;
  align-items: center;
  margin-left: 15px;
  gap: 10px;
  flex-wrap: wrap;

  .filterControl {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
    border-radius: 5px;
    font-size: var(--font-size-xs);
    color: var(--white-color);
    cursor: pointer;
    border: 1px solid #474B56;
    background-color: transparent;
    text-transform: capitalize;

    &.active {
      background-color: var(--accent-color);
      @include roboto-medium();
    }

    @include media-xxl {
      padding: 12px;
      font-size: var(--font-size-sm);
      border-radius: 10px;
    }
  }

}