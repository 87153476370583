@import 'reset';
@import 'variables.module.scss';
@import 'mixins';
@import 'src/assets/styles/mixins.scss';

body {
  font: var(--font-xs);

  &::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  @include media-xxl {
    font: var(--font-m);
  }
}

.app {
  min-height: 100vh;
  display: flex;
  overflow-x: hidden;
  line-height: normal;
}

.page-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--primary-color);
  margin-left: var(--sidebar-width);
}

textarea {
  resize: none;
}

.notistack-CollapseWrapper {
  margin-top: -3px;
  padding: 0px 0 10px 0 !important;

  @include media-xxl {
    margin-top: 0;
    padding: 3px 0 9px 0 !important;
  }
}


