@import 'src/assets/styles/mixins.scss';

.container {
    width: 100%;
    height: 10px;
    border-radius: 10px;
    position: relative;
    background-color: var(--primary-color);

    @include media-xxl {
        height: 12px;
        border-radius: 15px;
    }

    .bar {
        position: relative;
        height: 100%;
        width: 0;
        background-color: var(--accent-color);
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        overflow: hidden;
        transition: width 1s;

        @include media-xxl {
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
        }

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: -50%;
            width: 50%;
            height: 100%;
            background: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.6), transparent);
            animation: shimmer 1.5s infinite linear;
        }
    }

    .labels {
        display: flex;
        margin-top: 10px;
        width: 100%;
        justify-content: space-between;
        font-size: var(--font-size-xs);
        @include roboto-regular();
        color: var(--gray-dark-color);

        @include media-xxl {
            margin-top: 15px;
            font-size: var(--font-size-sm);
        }
    }

    .dot {
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        left: 0;
        background-color: var(--accent-color);
        border-radius: 50%;
        width: 16px;
        height: 16px;
        transition: left 1s;
        z-index: 2;

        @include media-xxl {
            width: 24px;
            height: 24px;
        }
    }

    .intervalDot {
        position: absolute;
        width: 2px;
        height: 2px;
        transform: translateY(-50%);
        top: 50%;
        left: 0;
        border-radius: 50%;
        background-color: var(--accent-color);

        @include media-xxl {
            width: 3px;
            height: 3px;
        }

        &.passed {
            background-color: var(--primary-color);
        }
    }

    @keyframes shimmer {
        100% {
            left: 100%;
        }
    }
}