@import 'src/assets/styles/mixins.scss';

.creator {
  display: flex;
  justify-content: space-between;
  padding: 14px 0;
  border-bottom: 1px solid var(--dark-border-color);
  align-items: center;

  @include media-xxl {
    padding: 20px 0;
  }

  .info {
    display: flex;

    .avatar {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: var(--gray-color);
      margin-right: 10px;

      @include media-xxl {
        width: 50px;
        height: 50px;
      }

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }

    .username {

      .ofNick {
        color: var(--gray-dark-color);
        font-size: var(--font-size-xs);
        line-height: 10px;

        @include media-xxl {
          font-size: 16px;
          line-height: 28px;
        }
      }

      .name {
        color: var(--white-color);
        font-size: var(--font-size-s);

        @include media-xxl {
          font-size: var(--font-size-m);

        }
      }
    }
  }

  .removeBtn {
    max-width: 90px;
    max-height: 30px;

    @include media-xxl {
      max-width: 140px;
      max-height: 40px;
    }

  }
}