.container {
  width: 100%;

  .row {
    display: flex;
    gap: 10px;

    .input {
      flex: 1;
      height: 100%;
    }
  }


  .input {
    margin-top: 20px;
  }
}