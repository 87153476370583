@import 'src/assets/styles/mixins.scss';

.title {
  margin-bottom: 16px;
  font-size: 12px;
  font-weight: 400;

  @include media-xxl {
    margin-bottom: 24px;
    font-size: 19px;
  }
}

.icon {
  margin-bottom: 10px;

  svg {
    width: 16px;
    height: 16px;

    @include media-xxl {
      width: 24px;
      height: 24px;
    }
  }
}

.value {
  display: flex;
  align-items: center;
  font-size: var(--font-size-heading);
  margin-bottom: 14px;
  @include roboto-medium();

  @include media-xxl {
    font-size: var(--font-size-l);
    margin-bottom: 18px;
  }

  svg {
    margin-right: 3px;
    width: 17px;
    height: 17px;

    @include media-xxl {
      width: 25px;
      height: 25px;
    }
  }

  &.loading {
    display: block;
    max-width: 300px;
  }
}

.progressBar {
  margin-top: 20px;
}
