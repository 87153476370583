@import 'src/assets/styles/mixins.scss';

.signIn {
  width: 0; // for animation showingProcess

  background-color: var(--primary-color);

  .form {
    display: flex;
    justify-content: center;
    flex-direction: column;

    background: var(--white-color);
    height: 100%;
    width: 100%;

    .body {
      opacity: 0; // for animation showingProcess

      margin: 0 auto;
      max-width: 300px;
      width: 100%;
      padding: 0 20px;

      @include media-xxl {
        max-width: 500px;
      }
    }


  }

  &.showingProcess {
    transition: width 1s;
    width: 40%;

    .body {
      transition: opacity .5s .5s;
      opacity: 1;
    }
  }

}