@import 'src/assets/styles/mixins.scss';

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 60px;

  @include media-xxl {
    padding: 0 75px;
  }


  .icon {
    margin-bottom: 10px;

    @include media-xxl {
      margin-bottom: 23px;
    }

    svg {
      width: 24px;
      height: 24px;

      @include media-xxl {
        width: 30px;
        height: 30px;
      }
    }
  }

  .title {
    margin-bottom: 5px;
    font-size: var(--font-size-heading);
    font-weight: 500;

    @include media-xxl {
      margin-bottom: 19px;
      max-width: 500px;
    }
  }

  .description {
    margin-bottom: 15px;
    max-width: 360px;
    text-align: center;
    @include roboto-light();
    line-height: 20px;

    @include media-xxl {
      margin-bottom: 20px;
      max-width: 570px;
    }
  }

  .actions {
    display: flex;
    margin-bottom: 20px;

    @include media-xxl {
      margin-bottom: 40px;
    }

    .input {
      margin-right: 15px;
      min-width: 248px;

      @include media-xxl {
        min-width: 320px;
      }
    }

    button {
      max-height: 42px;
      width: 140px;

      @include media-xxl {
        max-height: 65px;
        width: 220px;
      }
    }
  }
}