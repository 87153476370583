@import 'src/assets/styles/mixins.scss';

.title {
  color: var(--accent-color);
  margin-bottom: 25px;
  font-size: 16px;
  @include roboto-medium();

  @include media-xxl {
    margin-bottom: 30px;
    font-size: 24px;
  }
}

.table {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  flex: 1;
}

.aiTokens {
  display: flex;
  width: 100%;

  div {
    flex: 1;
    text-align: center;
  }
}

.prices {
  display: flex;
  justify-content: center;
  gap: 12px;
  padding: 5px;
  border-radius: 5px;
  background-color: #505767;
  width: 100%;

  @include media-xxl {
    padding: 10px;
  }

  .cell {
    display: flex;
    justify-content: center;
    flex: 1;

    .plan {
      max-width: 100px;
      padding: 6px 14px;
      border-radius: 10px;
      border: 1px solid var(--accent-color);
      color: var(--white-color);
      cursor: pointer;
      flex: 1;
      text-align: center;

      @include media-xxl {
        max-width: 120px;
      }

      .discount {
        color: var(--gray-dark-color);
        margin-right: 6px;
        text-decoration: line-through;
      }

      &.selected {
        background-color: var(--accent-color);
      }
    }
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 5px;


  @include media-xxl {
    margin-top: 10px;
  }

  .totals {
    p {
      @include roboto-regular();
      font-size: var(--font-size-xs);
      color: var(--gray-dark-color);

      @include media-xxl {
        font-size: 16px;
      }

      span {
        color: var(--gray-light-color);
        font-size: var(--font-size-s);
        font-weight: 600;

        @include media-xxl {
          font-size: 21px;
        }
      }
    }
  }

  .makePaymentBtn {
    width: initial;
    max-height: 50px;

    @include media-xxl {
      width: 170px;
    }
  }
}
