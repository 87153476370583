@import 'src/assets/styles/mixins.scss';

.container {
  width: 100%;
  max-width: var(--modal-width);
  padding: 20px;

  @include media-xxl {
    padding: 25px;
  }

  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;

    @include media-xxl {
      margin-bottom: 20px;
    }

    span {
      display: flex;
      color: var(--white-color);
      font-size: var(--font-size-sm);
      cursor: pointer;

      svg {
        position: relative;
        width: 10px;
        height: 10px;
        bottom: 4px;
        margin-right: 3px;

        @include media-xxl {

          width: 16px;
          height: 16px;
        }
      }
    }

    .close {
      width: 9px;
      height: 9px;
      position: relative;
      bottom: 4px;
      align-self: flex-end;
      fill: var(--black-color);
      cursor: pointer;

      @include media-xxl {
        width: auto;
        height: auto;
      }
    }


    &.withoutBackBtn {
      justify-content: flex-end;

      span {
        display: none;
      }
    }
  }

  .dots {
    width: 100%;
    display: flex;
    justify-content: center;

    .dot {
      width: 5px;
      height: 5px;
      margin-right: 4px;
      background-color: var(--gray-color);
      border-radius: 50%;
      transition: width .6s;

      &.active {
        width: 15px;
        background-color: var(--accent-color);
        border-radius: 15px;
      }

      @include media-xxl {
        width: 8px;
        height: 8px;

        &.active {
          width: 20px;
        }
      }
    }
  }

}
