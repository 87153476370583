@import 'src/assets/styles/mixins.scss';

.box {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 15px;
  border-radius: 15px;
  background-color: var(--secondary-color);

  @include media-xxl {
    padding: 26px 36px;
  }


  .titleBox {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 30px;

    @include media-xxl {
      margin-bottom: 45px;
      gap: 15px;
    }

    .title {
      font-size: var(--font-size-m);
      color: var(--white-color);
      font-weight: 500;

      @include media-xxl {
        font-size: var(--font-size-l);
      }
    }
  }

  .icon {
    display: flex;
    align-items: center;

    svg {
      height: 16px;
      width: 16px;

      @include media-xxl {
        height: 24px;
        width: 24px;
      }
    }
  }
}
