@import 'src/assets/styles/mixins.scss';



.root {
  --notification-background: transparent;
  --notification-text-color: #fff;
  --notification-border: transparent;
  --notification-color: var(--gray-light-color);

  position: relative;

  backdrop-filter: blur(120px);
  background-color: var(--notification-background);
  //filter: drop-shadow(-2px -2px 80px #0C0C0C);

  border-radius: 5px;
  align-items: center;

  padding: 10px;
  min-width: 230px;
  cursor: pointer;
  overflow: hidden;

  border: 1px solid var(--notification-border, transparent);

  @include media-xxl {
    min-width: 336px;
    padding: 15px;
  }

  &.info {
    --notification-background: rgba(78, 132, 181, 0.25);
    --notification-text-color: #9BEAFE;
  }

  &.success {
    --notification-background: rgba(53, 152, 146, 0.25);
    --notification-text-color: #A6EF94;
  }

  &.warning {
    --notification-background: rgba(216, 175, 114, 0.25);
    --notification-text-color: #EFDB94;
  }

  &.error {
    --notification-background: rgba(97, 48, 48, 0.25);
    --notification-text-color: #F65757;
  }

  &.white {
    &.info {
      --notification-background: #E3F4FE;
      --notification-text-color: #13262F;
      --notification-color: #384959;
      --notification-border: #004E89;
    }

    &.success {
      --notification-background: #EDF2EF;
      --notification-text-color: #189E5E;
      --notification-border: #189E5E;
    }

    &.warning {
      --notification-background: #FEF9EE;
      --notification-text-color: #E49620;
      --notification-color: #746755;
      --notification-border: #E49620;
    }

    &.error {
      --notification-background: #F3E9E9;
      --notification-text-color: #9F151D;
      --notification-border: #9F151D;
    }

    path {
      fill: var(--notification-text-color);
    }
  }


  .icon {
    display: flex;
    margin-right: 10px;
    z-index: 1;

    svg {
      width: 13px;
      height: 13px;

      @include media-xxl {
        width: 20px;
        height: 20px;
      }
    }


  }

  .content {
    padding-right: 40px;
    .title {
      color: var(--notification-text-color);
      font-size: var(--font-size-s);
      line-height: normal;
      @include roboto-medium();

      @include media-xxl {
        font-size: 16px;
      }
    }

    .text {
      color: var(--notification-color);
      font-size: 9px;
      margin-top: 2px;
      line-height: normal;
      @include roboto-regular();

      @include media-xxl {
        font-size: 13px;
      }
    }
  }

  .close {
    position: absolute;
    right: 10px;
    padding: 5px;
    //margin-bottom: 7px;

    svg {
      width: 8px;
      height: 8px;
    }

    &:hover {
        path {
            fill: var(--notification-text-color);
            transition: fill .3s;
        }
    }

    @include media-xxl {
      right: 15px;
      padding: 10px;

      svg {
        width: 10px;
        height: 10px;
      }
    }

  }
}
