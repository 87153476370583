@import 'src/assets/styles/mixins.scss';

.container {
  width: 100%;
  height: 100vh;
  padding: 0px 20px;
  background-color: var(--primary-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;

  background-image: url('/assets/images/error-bg.png');
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;

  @include media-xxl {
    padding-top: 80px;
  }

  .content {
    z-index: 1;
  }


  .brandLogo {
    position: absolute;
    bottom: 0;
    width: 585px;
    transform: translateY(90px);

    @include media-xxl {
      width: 922px;
      transform: translateY(0px);
    }
  }
}
