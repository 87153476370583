@import 'src/assets/styles/mixins.scss';

.textarea {
  width: 100%;

  .fieldName {
    display: block;
    @include field-label();
  }

  .control {
    display: block;
    border-radius: 5px;
    border: 1px solid var(--secondary-color);
    padding: 10px 0 10px 10px;
    background-color: var(--primary-color);
    color: var(--white-color);
    font-size: inherit;

    width: 100%;
    height: 96px;
    padding-right: 0;
    resize: none;

    @include media-xxl {
      padding-top: 20px;
      padding-bottom: 20px;
      border-radius: 10px;
    }

    &:focus {
      outline: none !important;
      transition: border-color .2s;
      border: 1px solid var(--accent-color);
    }

    &::placeholder {
      color: var(--gray-dark-color);
      @include roboto-light-italic();
    }
  }

  &.error {
    .fieldName {
      color: var(--error-color);
    }

    .errorText {
      margin-top: 2px;
      color: var(--error-color);
      margin-left: 2px;
      font-size: var(--font-size-sm);
      margin-bottom: -8px;
    }


    .control {
      border-color: var(--error-color);
    }

  }
}
