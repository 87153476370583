@import 'src/assets/styles/mixins.scss';

.container {
  width: 100%;
  padding: 20px;
  width: 440px;

  @include media-xxl {
    padding: 25px;
    width: 626px;
  }

  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    @include media-xxl {
      margin-bottom: 20px;
    }


    span {
      display: flex;
      color: var(--gray-dark-color);
      font-size: var(--font-size-sm);
      cursor: pointer;

      svg {
        position: relative;
        bottom: 4px;
        width: 10px;
        height: 9px;
        margin-right: 3px;

        @include media-xxl {
          width: auto;
          height: auto;
        }
      }
    }

    .close {
      position: relative;
      bottom: 4px;
      width: 9px;
      height: 9px;
      align-self: flex-end;
      fill: var(--black-color);
      cursor: pointer;

      @include media-xxl {
        width: auto;
        height: auto;
      }
    }

    &.withoutBackBtn {
      justify-content: flex-end;

      span {
        display: none;
      }
    }
  }

}