@import 'src/assets/styles/mixins.scss';

.form {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 16px;

  @include media-xxl {
    gap: 25px;
  }

  .input {
    input {
      height: 42px;

      @include media-xxl {
        height: 50px;
      }
    }
  }

  .select {
    min-width: auto;

    :global {
      .select__control {
        .select__value-container {
          height: 42px;
          padding-top: 6px;
          padding-bottom: 6px;

          @include media-xxl {
            height: 50px;
          }
        }
      }

    }
  }

  .button {
    height: 34px;
    margin-top: auto;
    width: fit-content;
    align-self: flex-end;
    border-radius: 5px;

    @include media-xxl {
      height: 50px;
    }
  }

  .text {
    margin-top: 12px;
    color: var(--gray-dark-color);
    font-size: var(--font-size-s);
  }
}
