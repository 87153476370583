@import 'src/assets/styles/mixins.scss';

.container {
  display: flex;
  flex-direction: column;

  height: 100%;
  padding: 25px 40px;

  @include media-xxl {
    padding: 30px 40px;
  }


  .header {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    @include media-xxl {
      margin-bottom: 20px;
    }

    .title {
      font-size: 18px;
      color: var(--white-color);
      font-weight: 500;

      @include media-xxl {
        font-size: 28px;
      }
    }
  }

  .cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;

    @include media-xxl {
      gap: 30px;
    }
  }
}
