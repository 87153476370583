@import 'src/assets/styles/mixins.scss';

.table {
  margin-top: 20px;
  padding: 20px;
  background-color: var(--secondary-color);
  color: var(--white-color);
  border-radius: 15px;


  @include media-xxl {
    margin-top: 30px;
    padding: 25px;
  }

  .top {
    width: 100%;
    display: flex;
    margin-bottom: 30px;

    p {
      flex: 0.75;
      font-size: var(--font-size-heading);
      font-weight: 500;

      &.title {
        flex: 1;
      }

      @include media-xxl {
        font-size: var(--font-size-l);
      }
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;


    @include media-xxl {
      margin-top: 30px;
    }

    .totals {
      margin-right: 120px;

      p {
        font-weight: 400;
        font-size: var(--font-size-xs);
        color: var(--gray-dark-color);

        @include media-xxl {
          font-size: 16px;
        }

        span {
          color: var(--gray-light-color);
          font-size: var(--font-size-s);
          font-weight: 600;

          @include media-xxl {
            font-size: 21px;
          }
        }
      }
    }

    .makePaymentBtn {
      width: initial;
      max-height: 50px;

      @include media-xxl {
        width: 170px;
      }
    }
  }

  .account {
    display: flex;
    align-items: center;

    .avatar {
      margin-right: 10px;
      width: 25px;
      min-width: 25px;
      height: 25px;
      border-radius: 100%;
      background-color: var(--gray-color);

      @include media-xxl {
        width: 40px;
        min-width: 40px;
        height: 40px;
      }

      img {
        object-fit: cover;
        width: 25px;
        min-width: 25px;
        height: 25px;
        border-radius: 50%;

        @include media-xxl {
          width: 40px;
          min-width: 40px;
          height: 40px;
        }
      }
    }

    .info {
      display: flex;
      flex-direction: column;


      .name {
        color: var(--white-color);
        font-size: var(--font-size-s);

        @include media-xxl {
          font-size: var(--font-size-m);
        }
      }

      .ofNick {
        font-weight: 300;
        font-size: var(--font-size-xs);

        @include media-xxl {
          font-size: 16px;
        }
      }
    }
  }

  .earnings {
    color: var(--accent-color);
    font-size: var(--font-size-sm);
    font-weight: 600;

    @include media-xxl {
      font-size: 20px;
    }
  }

  .statusPlan {
    display: flex;
    flex-direction: column;
    align-items: center;

    .status {
      color: var(--white-color);
      font-size: var(--font-size-sm);

      @include media-xxl {
        font-size: 20px;
      }
    }

    .expiry {
      font-size: 7px;


      @include media-xxl {
        font-size: var(--font-size-xs);
      }
    }
  }

  .plan {
    padding: 6px 14px;
    border-radius: 10px;
    border: 1px solid #F7931A;
    color: var(--white-color);
    cursor: pointer;

    .discount {
      color: var(--gray-dark-color);
      margin-right: 6px;
      text-decoration: line-through;
    }

    &.disabled {
      border-color: var(--gray-dark-color) !important;
      color: var(--gray-dark-color) !important;
      background-color: transparent !important;
    }
  }
}

.tooltip {

  :global {
    .MuiTooltip-tooltip {
      max-width: 200px;
    }
  }
}

.info {
  cursor: pointer;
  margin-left: 10px;
}
