$roboto-path: "../fonts/Roboto/Roboto-";

// Roboto-Regular
@font-face {
  font-family: 'Roboto';
  src: url("#{$roboto-path}Regular.ttf");
  src: url("#{$roboto-path}Regular.ttf") format("truetype");
  font-style: normal;
  font-display: swap;
  font-weight: 400;
}

// Roboto-Medium
@font-face {
  font-family: 'Roboto';
  src: url("#{$roboto-path}Medium.ttf");
  src: url("#{$roboto-path}Medium.ttf") format("truetype");
  font-style: normal;
  font-display: swap;
  font-weight: 500;
}

// Roboto-Light
@font-face {
  font-family: 'Roboto';
  src: url("#{$roboto-path}Light.ttf");
  src: url("#{$roboto-path}Light.ttf") format("truetype");
  font-style: normal;
  font-display: swap;
  font-weight: 300;
}

// Roboto-LightItalic
@font-face {
  font-family: 'Roboto';
  src: url("#{$roboto-path}LightItalic.ttf");
  src: url("#{$roboto-path}LightItalic.ttf") format("truetype");
  font-style: italic;
  font-display: swap;
  font-weight: 300;
}