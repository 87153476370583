@import 'src/assets/styles/mixins.scss';

.membersTable {
  display: flex;
  flex-direction: column;

  height: 100%;

  padding: 30px 40px;

  @include media-xxl {
    padding: 30px 60px;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    .addMember {
      display: flex;
      align-items: center;

      .title {
        font-size: var(--font-size-m);
        color: var(--white-color);
        margin-right: 27px;

        @include roboto-medium();

        @include media-xxl {
          font-size: var(--font-size-heading1);
        }
      }

      .addBtn {
        height: 34px;
        padding: 10px 20px;
        border-radius: 5px;
        @include roboto-regular();
        width: auto;


        @include media-xxl {
          height: 50px;
          padding: 20px 40px;
          border-radius: 10px;
        }
      }
    }

  }

  .tableContainer {
    height: 100%;
    background-color: var(--secondary-color);
    border-radius: 15px;

    .table {
      align-self: flex-start;

      .member {
        display: flex;
        align-items: center;

        .avatar {
          margin-right: 12px;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background-color: var(--gray-color);

          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }

        .name {
          display: flex;
          flex-direction: column;
          color: var(--white-color);
        }
      }

      .role {
        padding: 6px 18px;
        border-radius: 10px;
        text-transform: capitalize;
        color: var(--white-color);

        &.owner {
          background-color: rgba(0, 94, 15, 0.12);
        }
      }

      .email {
        color: var(--white-color);
      }

      .creatorAssigned {
        .add {
          display: flex;
          align-items: center;
          cursor: pointer;

          svg {
            margin-right: 8px;
          }
        }

        span {
          display: block;
          color: var(--white-color);
          max-width: 240px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .action {
        color: var(--white-color);
        cursor: pointer;

        svg {
          height: 15px;

          @include media-xxl {
            height: 20px;
          }
        }
      }


    }
  }
}

.actionMenu {
  font: var(--font-m);
  background-color: var(--secondary-color);


  .item {
    border: none;
    background-color: transparent;
    color: var(--white-color);
    width: 100%;
    display: flex;
    align-items: center;

    padding: 0px 6px;
    cursor: pointer;
    transition: all .3s;
    font-size: var(--font-size-xs);

    svg {
      width: 10px;
      height: 10px;
      margin-right: 4px;

      &.assignIcon {
        * {
          stroke: var(--white-color);
        }
      }

      @include media-xxl {
        width: 14px;
        height: 14px;
        margin-right: 8px;
      }
    }

    &:hover {
      background-color: rgba(91, 192, 235, .3);
      color: var(--accent-color);

      svg {
        &.assignIcon {
          * {
            stroke: var(--accent-color);
          }
        }
      }
    }

    @include media-xxl {
      padding: 10px;
      font-size: var(--font-size-sm);
    }
  }

}

:global(.MuiPopover-paper) {
  background-color: var(--secondary-color) !important;
}