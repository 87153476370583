@import 'src/assets/styles/mixins.scss';

.sidebar {
  position: fixed;
  height: 100vh;
  min-width: var(--sidebar-width);
  display: flex;
  flex-direction: column;
  background: var(--secondary-color);


  .header {
    display: flex;
    align-items: center;
    padding: 16px 12px;

    svg {
      width: 34px;
      height: 25px;
      fill: var(--accent-color);
      margin-right: 6px;

      @include media-xxl {
        width: 54px;
        height: 40px;
        margin-right: 10px;
      }
    }

    .title {
      font-size: var(--font-size-sm);
      color: var(--white-color);
      @include roboto-regular();

      span {
        color: var(--accent-color);
        margin-right: 3px;
        font-weight: 700;
      }

      .postfix {
        color: var(--gray-color);
        margin-left: 2px;
        font-size: var(--font-size-xs);
        @include roboto-thin();

        @include media-xxl {
          margin-left: 4px;
          font-size: var(--font-size-sm);
        }
      }

      @include media-xxl {
        font-size: calc(var(--font-size-m) + 1px);
      }
    }

    @include media-xxl {
      padding: 24px 28px;
    }
  }

  .menu {
    flex: 1;
    padding-top: 54px;

    @include media-xxl {
      padding-top: 90px;
    }
  }

  .footer {
    padding: 28px 0;
    display: flex;
    flex-direction: column;
    gap: 20px;

    @include media-xxl {
      padding: 40px 0;
      gap: 30px;
    }

    .button {
      display: flex;
      cursor: pointer;
      align-items: center;
      padding: 0 17px;

      svg {
        width: 16px;
        height: 16px;
        fill: var(--slightly-transparent-color);
        margin-right: 12px;
        transition: fill .5s;

        @include media-xxl {
          width: 24px;
          height: 24px;
          top: 2px;
          margin-right: 22px;
        }
      }

      &:first-child {
        svg {
          width: 19px;
          height: 19px;
          margin-right: 9px;

          @include media-xxl {
            width: 29px;
            height: 29px;
            margin-right: 17px;
          }
        }
      }

      span {
        font-size: var(--font-size-heading);
        color: var(--slightly-transparent-color);
        transition: color .5s;

        @include media-xxl {
          font-size: var(--font-size-heading0);
        }
      }

      &:hover {
        svg {
          fill: var(--white-color);
        }

        span {
          color: var(--white-color);
        }
      }

      @include media-xxl {
        padding: 0 30px;
      }
    }
  }
}