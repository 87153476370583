@import 'src/assets/styles/mixins.scss';

.box {
  border-radius: 5px;
  background-color: var(--gray-dark-color);
  padding: 5px 10px;
  font-size: 9px;

  @include media-xxl {
    padding: 5px 15px;
    font: var(--font-m);
    border-radius: 10px;
  }
}

.activeBox {
  background-color: var(--pink-color);
}
