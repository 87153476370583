@import 'src/assets/styles/mixins.scss';

.container {
  width: 100%;
  padding: 20px;
  min-width: 870px;

  @include media-xxl {
    padding: 25px;
    min-width: 1305px;
  }

  .header {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    svg {
      cursor: pointer;
    }
  }
}

.body {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .icon {
    width: 20px;
    height: 20px;
    margin-bottom: 10px;

    @include media-xxl {
      width: 30px;
      height: 30px;
      margin-bottom: 20px;
    }
  }

  .title {
    margin-bottom: 30px;
    font-size: var(--font-size-l);
    font-weight: 500;
  }

  .table {
    max-height: calc(90vh - 180px);
    overflow-y: auto;
    scrollbar-width: none;

    .date {
      color: var(--white-color);
    }

    .amount {
      color: var(--black-color);

      &.loss {
        color: #F66869;
      }

      &.increase {
        color: #A6EF94;
      }

      &.zero {
        color: #5BC0EB;
      }
    }

    td {
      white-space: unset;
    }
  }


}
