@import 'src/assets/styles/mixins.scss';

.container {
  display: flex;

  margin-top: 20px;
  padding: 20px;
  background-color: var(--secondary-color);
  color: var(--white-color);
  border-radius: 15px;

  @include media-xxl {
    margin-top: 30px;
    padding: 25px;
  }

  .progress {
    flex: 3.2;
    padding-right: 40px;
    border-right: 1px solid #474b56;
    padding-bottom: 20px;

    @include media-xxl {
      padding-right: 55px;
      padding-bottom: 30px;
    }
  }

  .payment {
    flex: 2;
    padding-left: 20px;

    @include media-xxl {
      padding-left: 25px;
    }
  }
}
