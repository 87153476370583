@import 'src/assets/styles/mixins.scss';


.tooltip {
  &:global(.MuiTooltip-popper) {
    z-index: var(--tooltip-z-index);
  }

  :global {
    .MuiTooltip-tooltip {
      background-color: var(--primary-color);
      border-radius: 5px;
      font-size: var(--font-size-xs);
      color: var(--gray-dark-color);
      padding: 8px 6px;
      @include roboto-regular();

      @include media-xxl {
        font-size: 13px;
      }

      .MuiTooltip-arrow {
        color: var(--primary-color);
      }
    }
  }
}
