@import 'src/assets/styles/mixins.scss';

.item {
  width: 100%;
  padding: 11px 17px;

  .link {
    display: flex;
    color: var(--slightly-transparent-color);
    text-decoration: none;
    font-size: var(--font-size-heading);
    @include roboto-regular();

    .icon {
      width: 16px;
      height: 16px;
      position: relative;
      margin-right: 12px;
      fill: var(--slightly-transparent-color);

      @include media-xxl {
        width: 24px;
        height: 24px;
        top: 2px;
        margin-right: 22px;
      }
    }

    @include media-xxl {
      font-size: var(--font-size-heading0);
    }
  }

  &.active {
    padding-left: 14px; // need reduce when active because added left border
    border-left: 3px solid var(--accent-color);
    border-radius: 2px;

    //border-top-left-radius: 5px;
    //border-top-right-radius: 5px;

    .link {
      color: var(--white-color);
      @include roboto-medium();

      .icon {
        fill: var(--white-color);
      }
    }
  }

  @include media-xxl {
    padding: 15px 30px;

    &.active {
      padding-left: 27px; // need reduce when active because added left border
    }
  }
}