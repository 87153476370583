@import 'src/assets/styles/mixins.scss';

.box {
  background-color: var(--secondary-color);
  padding: 20px 15px;
  border-radius: 10px;

  @include media-xxl {
    padding: 26px 36px;
    border-radius: 15px;
  }

  .form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;

    @include media-xxl {
      gap: 25px;
    }

    .field {

      input {
        height: 38px;
        padding-top: 0;
        padding-bottom: 0;

        @include media-xxl {
          height: 50px;
        }
      }
    }

    .notes {
      textarea {
        resize: vertical;
        height: 350px;
      }
    }
  }

  .formInner {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    row-gap: 15px;

    @include media-xxl {
      row-gap: 25px;
    }
  }


  .style {
    width: 50%;
  }

  .style,
  .select {


    :global {
      .select__control {
        .select__value-container {
          height: 34px;
          padding-top: 0;
          padding-bottom: 0;

          @include media-xxl {
            height: 50px;
          }
        }
      }

    }
  }

  .btnBox {
    margin-top: 35px;
  }

  .btn {
    height: 34px;
    width: fit-content;
    margin: 0 auto;
    padding: 10px 36px;
    font-size: var(--font-size-xs);

    @include media-xxl {
      height: 50px;
      padding: 10px 60px;
      font-size: var(--font-size-m);
    }
  }


  .line {
    background: var(--dark-border-color);
    height: 1px;
    width: 100%;
    margin: 30px 0;
  }
}
